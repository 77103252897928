<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card icon="AlertCircleIcon" :statistic="counter.open" statistic-title="New Invoice" color="primary" :icon-right="true" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card icon="MinusCircleIcon" :statistic="counter.ongoing" statistic-title="Ongoing Invoice" color="warning" :icon-right="true" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 mb-base">
        <statistics-card icon="CheckCircleIcon" :statistic="counter.closed" statistic-title="Done Invoice" color="success" :icon-right="true" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card>
          <vs-input icon-after="true" icon-pack="feather" icon="icon-search" label-placeholder="Search" v-model="search.name" class="w-full no-icon-border is-label-placeholder" />
          <br>
          <div class="vs-con-loading__container" id="div-with-loading">
            <vs-table stripe :data="invoices" @selected="navigate">
              <template slot="thead">
                <vs-th>Receipt</vs-th>
                <vs-th>Customer</vs-th>
                <vs-th>Total Items</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Date</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="tr.id" v-for="(tr) in data" :data="tr">
                  <vs-td :data="tr.invoice_no">
                    {{ tr.invoice_no }}
                  </vs-td>

                  <vs-td :data="tr.customer.name">
                    {{ tr.customer.name }}
                  </vs-td>

                  <vs-td :data="tr.total_items">
                    {{ tr.total_items }}
                  </vs-td>

                  <vs-td :data="tr.status">
                    <vs-chip :color="status_color[tr.status]">
                      {{ status_name[tr.status] }}
                    </vs-chip>
                  </vs-td>

                  <vs-td :data="tr.date">
                    {{ tr.date }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <div slot="footer">
            <vs-button color="success" type="filled" to="/invoices/create" icon="add" v-if="isBranchAdminOrFinance">
              Create Invoice
            </vs-button>
            <vs-pagination :total="meta.total_page" v-model="meta.page" :max="7" />
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { CLEARANCE, INVOICE_STATUS_NAME, STATUS_COLOR } from '@/utils/constants';
import InvoiceRepo from '@/repositories/invoice_repo';
import DashboardRepo from '@/repositories/dashboard_repo';
import StatisticsCard from '@/components/cards/StatisticsCard.vue';
import Pagination from '@/utils/mixins/pagination';

export default {
  mixins: [Pagination],
  data: () => ({
    status_name: INVOICE_STATUS_NAME,
    status_color: STATUS_COLOR,
    invoices: [],
    counter: {
      open: 0,
      ongoing: 0,
      closed: 0
    },
    clearance: CLEARANCE
  }),
  methods: {
    loadData({
      page = 1, per_page = 5, search, debounce = true
    }) {
      this.loadingTrue();
      const func = debounce ? InvoiceRepo.listDebounce : InvoiceRepo.list;
      return func(page, per_page, search)
        .then(({ data: { data: invoices, pagination: meta } }) => {
          this.invoices = invoices.map(item => ({
            ...item,
            date: moment(item.invoice_date).format('DD/MM/YYYY')
          }));
          this.meta = meta;
          this.loadingFalse();
        });
    },
    loadCounter() {
      return DashboardRepo.counterInvoice()
        .then(({ data: content }) => {
          this.counter = content;
        });
    },
    navigate(item) {
      this.$router.push(`/invoices/${item.invoice_no}`);
    }
  },
  components: {
    StatisticsCard
  },
  computed: {
    isBranchAdminOrFinance() {
      return [this.clearance.BRANCH_ADMIN, this.clearance.FINANCE].includes(this.$store.getters.getClearance);
    }
  },
  async mounted() {
    await this.loadData({});
    await this.loadCounter({});
  }
};
</script>
